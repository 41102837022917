var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pt-0"},[(!_vm.myRoleSettings)?_c('fc-role-loading',{attrs:{"my-role-settings":_vm.myRoleSettings,"page-title":_vm.pageTitle}}):[_c('v-toolbar',{attrs:{"flat":"","color":"transparent"}},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.pageTitle))]),_c('v-spacer'),(_vm.myRoleSettings.get)?_c('v-sheet',{staticClass:"ml-1",attrs:{"outlined":"","rounded":"","min-width":"200px"}},[_c('v-text-field',{attrs:{"label":"フリーワードで絞り込み","solo":"","flat":"","dense":"","hide-details":""},model:{value:(_vm.searchWord),callback:function ($$v) {_vm.searchWord=$$v},expression:"searchWord"}})],1):_vm._e(),(_vm.myRoleSettings.getHistories)?_c('v-btn',{staticClass:"ml-1",attrs:{"elevation":"0","color":"primary","to":"/gifts/histories"}},[_vm._v("特典履歴")]):_vm._e(),(_vm.myRoleSettings.getConditions)?_c('v-btn',{staticClass:"ml-1",attrs:{"elevation":"0","color":"primary","to":"/gifts/conditions"}},[_vm._v("特典条件")]):_vm._e(),(_vm.myRoleSettings.createAndUpdate)?_c('v-btn',{staticClass:"ml-1",attrs:{"elevation":"0","color":"primary","to":"/gift/create"}},[_vm._v("新しい特典を登録")]):_vm._e()],1),(_vm.myRoleSettings.get)?[_c('v-switch',{staticClass:"pa-0 ma-0 px-3",attrs:{"label":"削除済を表示する"},model:{value:(_vm.isDisplayItem),callback:function ($$v) {_vm.isDisplayItem=$$v},expression:"isDisplayItem"}}),_c('v-sheet',{attrs:{"outlined":"","rounded":""}},[_c('v-data-table',{staticClass:"fixed-header",attrs:{"headers":_vm.headers,"items":_vm.displayGifts,"items-per-page":-1,"loading":_vm.gifts.fetchLoading,"loader-height":"2","hide-default-footer":"","height":_vm.tableHeight},scopedSlots:_vm._u([{key:"item.isArchive",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{class:{
                primary: !item.isArchive && !item.isExpiredGift,
                warning: !item.isArchive && item.isExpiredGift,
              },attrs:{"small":""}},[(item.isArchive)?_c('span',[_vm._v("削除済")]):(item.isExpiredGift)?_c('span',[_vm._v("有効期限切れ")]):_c('span',[_vm._v("有効期限内")])])]}},{key:"item.title",fn:function(ref){
              var item = ref.item;
return [(!item.isArchive)?_c('router-link',{attrs:{"to":("/gift/" + (item.giftId))}},[_vm._v(" ID: "+_vm._s(item.giftId)+" ")]):_c('span',[_vm._v("ID: "+_vm._s(item.giftId))]),_c('br'),_vm._v(_vm._s(item.giftName)+" ")]}},{key:"item.expiration",fn:function(ref){
              var item = ref.item;
return [(item.expirationType)?_c('div',[_vm._v("タイプ："+_vm._s(_vm.getExpirationTypeText(item.expirationType)))]):_vm._e(),(item.expirationType === 'period')?_c('div',[_vm._v(_vm._s(item.expirationPeriod.intervalCount)),(item.expirationPeriod.interval === 'month')?_c('span',[_vm._v("ヶ")]):_vm._e(),_vm._v(_vm._s(_vm.getExpirationPeriodIntervalText(item.expirationPeriod.interval))+"間")]):(item.expirationType === 'date')?_c('div',[_vm._v("〜"+_vm._s(new _vm.DisplayDate(item.expirationDate).dateTime))]):_vm._e()]}},{key:"item.isUsable",fn:function(ref){
              var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.isUsable ? 'ON' : 'OFF'))])]}},{key:"item.actions",fn:function(ref){
              var item = ref.item;
return [(_vm.myRoleSettings.getHistories)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-btn',{staticClass:"ma-2",attrs:{"text":"","icon":"","small":"","to":{
                    name: 'GiftHistories',
                    params: { giftName: item.giftName },
                  }}},[_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v(" mdi-history ")])],1)]}}],null,true)},[_c('span',[_vm._v("特典履歴")])]):_vm._e(),(_vm.myRoleSettings.addGifts)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-btn',{staticClass:"ma-2",attrs:{"text":"","icon":"","small":"","disabled":item.isArchive},on:{"click":function($event){return _vm.open(item.giftId)}}},[_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v(" mdi-upload ")])],1)]}}],null,true)},[_c('span',[_vm._v("ユーザーを指定して付与する")])]):_vm._e(),(_vm.myRoleSettings.createAndUpdateConditions)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-btn',{staticClass:"ma-2",attrs:{"text":"","icon":"","small":"","disabled":item.isArchive,"to":("/gifts/conditions/create?giftId=" + (item.giftId))}},[_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v(" mdi-plus ")])],1)]}}],null,true)},[_c('span',[_vm._v("条件を指定して付与する")])]):_vm._e(),(_vm.myRoleSettings.getConditions)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-btn',{staticClass:"ma-2",attrs:{"text":"","icon":"","small":"","disabled":item.isArchive,"to":("/gifts/conditions?giftId=" + (item.giftId))}},[_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v(" mdi-eye-arrow-right ")])],1)]}}],null,true)},[_c('span',[_vm._v("特典条件を確認する")])]):_vm._e(),(_vm.myRoleSettings.delete)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-btn',{staticClass:"ma-2",attrs:{"text":"","icon":"","small":"","disabled":item.isArchive},on:{"click":function($event){return _vm.deleteGift(item.giftId)}}},[_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v(" mdi-delete ")])],1)]}}],null,true)},[_c('span',[_vm._v("削除")])]):_vm._e()]}}],null,true)})],1)]:_c('fc-role-deny')],_c('v-dialog',{attrs:{"scrollable":"","persistent":"","no-click-animation":"","retain-focus":false,"max-width":"900"},model:{value:(_vm.active),callback:function ($$v) {_vm.active=$$v},expression:"active"}},[_c('v-card',{attrs:{"loading":_vm.isCheckUsers}},[_c('v-card-text',{staticClass:"pt-5"},[_c('div',{staticClass:"mb-2 font-weight-bold"},[_vm._v("リストで登録")]),_c('v-row',{staticClass:"justify-space-between align-center"},[_c('v-col',[_c('v-file-input',{attrs:{"label":"ユーザーリスト（CSVファイル）","accept":".csv","prepend-icon":"mdi-upload","dense":"","hide-details":""},on:{"change":_vm.setCsv}})],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',{attrs:{"elevation":"0","color":"primary","width":"230px"},on:{"click":_vm.upload}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-account-plus")]),_vm._v(" リストで指定して登録 ")],1)],1)],1),(_vm.items.length)?_c('div',{staticClass:"mt-6"},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-account-multiple")]),_vm._v(" 登録したユーザー："+_vm._s(_vm.items.length)+"名 ")],1):_vm._e(),(_vm.items.length)?_c('v-data-table',{staticClass:"fixed-header",attrs:{"headers":_vm.dialogTableHeaders,"items":_vm.items,"items-per-page":-1,"loader-height":"2","hide-default-footer":"","disable-sort":"","height":_vm.tableHeight},scopedSlots:_vm._u([{key:"item.userId",fn:function(ref){
                  var item = ref.item;
return [(item.status === 'valid')?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"success"}},'v-icon',attrs,false),on),[_vm._v(" mdi-checkbox-marked-circle ")])]}}],null,true)},[_c('span',[_vm._v("ユーザーデータ確認済")])]):(item.status === 'invalid')?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"error"}},'v-icon',attrs,false),on),[_vm._v(" mdi-alert-circle ")])]}}],null,true)},[_c('span',[_vm._v("対象のユーザーが存在しません")])]):_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v(" mdi-account ")])]}}],null,true)},[_c('span',[_vm._v("ユーザーデータ未確認")])]),_c('span',{staticClass:"ml-2",domProps:{"textContent":_vm._s(item.userId)}})]}},{key:"item.quantity",fn:function(ref){
                  var item = ref.item;
return [_c('fc-number-form',{attrs:{"min":1,"disabled":!!_vm.failedUserIds.length},model:{value:(item.quantity),callback:function ($$v) {_vm.$set(item, "quantity", _vm._n($$v))},expression:"item.quantity"}})]}},{key:"item.actions",fn:function(ref){
                  var item = ref.item;
return [(!_vm.failedUserIds.length)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-btn',{staticClass:"ma-2",attrs:{"text":"","icon":"","small":"","disabled":item.isArchive},on:{"click":function($event){return _vm.deleteUpdateGift(item)}}},[_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v(" mdi-delete ")])],1)]}}],null,true)},[_c('span',[_vm._v("削除")])]):_vm._e()]}}],null,true)}):_vm._e(),(!_vm.items.length)?_c('div',{staticClass:"mt-3"},[_c('v-btn',{attrs:{"elevation":"0","color":"primary"},on:{"click":_vm.download}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-cloud-download")]),_vm._v(" ユーザーリストの雛形をダウンロード ")],1)],1):_vm._e(),_c('div',{staticClass:"mt-9 font-weight-bold"},[_vm._v("個別で登録")]),_c('div',{staticClass:"mt-1"},[_c('v-btn',{attrs:{"elevation":"0","color":"primary","to":"/users"}},[_vm._v(" ユーザー一覧へ ")]),_c('div',{staticClass:"grey--text caption mt-1"},[_vm._v(" ※ユーザーID＞持っている特典＞特典追加から"),_c('br'),_vm._v(" 任意のユーザーに個別で特典付与することが可能 ")])],1),(_vm.failedUserIds.length)?_c('div',[_c('div',{staticClass:"mt-9 font-weight-bold"},[_vm._v(" 特典を付与できなかったユーザーID ")]),_c('div',_vm._l((_vm.failedUserIds),function(userId){return _c('div',{key:userId},[_vm._v(" "+_vm._s(userId)+" ")])}),0)]):_vm._e(),_c('div',{staticClass:"text-right"},[_c('v-btn',{staticClass:"grey lighten-3",attrs:{"disabled":_vm.isCheckUsers},on:{"click":_vm.close}},[_vm._v("閉じる")]),(_vm.items.length && _vm.isErrorUser && _vm.myRoleSettings.getUsers && _vm.isPermitted)?_c('v-btn',{staticClass:"primary ml-2",on:{"click":_vm.checkUserIds}},[_vm._v("確認する")]):(_vm.items.length && !_vm.isAddGiftDone)?_c('v-btn',{staticClass:"primary ml-2",on:{"click":_vm.add}},[_vm._v("追加する")]):_vm._e()],1)],1)],1)],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }